<template>
    <div id="my-app" style="background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding:1rem">
                    <h2 class="pb-5" style="font-weight: 600">Stacked Spreads</h2>
                    <h3 class="pb-3">Tools and Filters</h3>
                    <h4><b>Show/Hide Label: </b>show or hide information labels on the chart.</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>

        <table v-if="namespace !== 'undefined'" border=0 style="text-align: left; margin: 2px 0 -2px 3px; vertical-align: bottom;"> 
            <tr style="text-align: left; margin-bottom: 0px; vertical-align: bottom;">
                <td style="vertical-align: bottom; padding: 0px 0px 0px 5px; background-color: white; width: 100%">

            <b-container fluid style="width: auto; margin-top: 0px; background-color: white; margin-left: -20px;">
                <b-row align-h="start" align-v="end" style="background-color: white; padding: 1px" class="row no-gutters">
                  
                <b-col md="auto" style="margin: 0px 0px 1px -2px; padding: 1px">  
                        <trade-specs v-if="aligned" v-bind:namespace="namespace"></trade-specs>
                    </b-col>

                    <b-col md="auto" style="margin: 0 2px 1px 2px; padding: 1px">  
                        <commodity-box v-bind:namespace="namespace">
                        </commodity-box>
                    </b-col>
              
              <b-col>
                <b-button-group style="border: 1px solid gray; background-color: #e7edf5; margin: 1px 2px 2px 0px; border-radius: 3px;
                         padding: 1px 0 2px 0;">
                    <span  style="margin: 2px 13px 0 4px; font-weight: bold; whitespace: nowrap; width: auto"> 
                        <b-form-select size="sm" v-model="aligned" :options="alignmentOptions" style="border:1px solid gray; width: 100px" ></b-form-select>
                    </span>
                    <balloon-toggle v-bind:namespace="namespace" style="margin: 2px 0 0 1px"></balloon-toggle>
                    <three-d-toggle v-if="aligned" v-bind:namespace="namespace" v-bind:property="'show3D'" style="margin: 2px 0 0 2px;"></three-d-toggle>
                    <horizontal-scrollbar-toggle v-bind:namespace="namespace" style="margin: 2px 1px 0 1px"></horizontal-scrollbar-toggle>                   

                <!--    <span style="border: 0px solid gray; padding: 2px;">
                        <y1 v-bind:namespace="namespace"></y1>
                    </span> 
                -->
                </b-button-group>

                <b-button-group style="border: 1px solid gray; padding: 0px; margin: 0 19px 1px 0px; white-space: nowrap; 
                                background-color: #e7edf5; border-radius: 3px;"> 

                    <stock-graph-type-toggle v-if="!aligned" v-bind:namespace="namespace" v-bind:click-handler="'updateChart'" style="padding: 2px"></stock-graph-type-toggle>
                    <truncate-selector v-if="!aligned" v-bind:namespace="namespace" style="margin: 1px 6px 0 7px"></truncate-selector> 

                    <longterm-chart-colors v-if="stockGraphType !== 'line' && !aligned" v-bind:namespace="namespace"></longterm-chart-colors>

                    <normalization v-if="aligned" v-bind:namespace="namespace" style="margin: 2px 0px 0 -2px;"></normalization>
                   
                    <b-button size="sm" v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray;
                              margin: 2px; border-radius: 3px;">Help</b-button>
                </b-button-group>
              </b-col>
                  </b-row>
            </b-container>
            </td>
            </tr>
        </table> 

    </div>
</template>

<script>
    import commodityBox from '@/components/commodity-box';
    import truncateSelector from '@/components/truncate';
    import stockGraphTypeToggle from '@/components/stock-graph-type-toggle';
   // import y1 from '@/components/y1.vue';
    import longtermChartColors from '@/components/longterm-chart-colors.vue';
    import balloonToggle from '@/components/balloon-toggle';
    import threeDToggle from '@/components/three-d-toggle';
    import normalization from '@/components/normalization';
    import horizontalScrollbarToggle from '@/components/horizontal-scrollbar-toggle';
    import tradeSpecs from '@/components/trade-specs';


    export default {
        components: {
            commodityBox, truncateSelector, stockGraphTypeToggle,/* y1, */longtermChartColors, balloonToggle,
             threeDToggle, normalization, horizontalScrollbarToggle, tradeSpecs
        },
        mounted() {
            console.log("long-term-chart-input.vue mounted() starting. this.namespace=", this.namespace);

            let observer = new ResizeObserver(entries => {
               // console.log("ResizeObserver() starting. this.namespace=", this.namespace, " activeModuleName=", this.$store.getters['activeModuleName']);
                if (this.$store.getters['activeModuleName'] === this.namespace) {
                   // console.log("checking input height.");
                    for (let entry of entries) {
                        // console.log("entry=", entry);
                        // Now do something with the resized element
                       // console.log("entry.contentRect.height=", entry.contentRect.height);
                        this.$store.commit(this.namespace + '/browserSideOnly/setInputDivHeight', entry.contentRect.height);
                    }
                }
            });
            let element = document.querySelector('#' + this.namespace + 'InputDiv');
           // console.log("element=", element);
            observer.observe(element);
        },
        props: ['namespace'],
        data: function () {
            return{
                alignmentOptions: [
                    {value: true, text: 'aligned'},
                    {value: false, text: 'unaligned'}
                ]
            }
        },
        computed: {
            aligned: {
                get() {
                    return this.$store.state[this.namespace].aligned;
                },
                set(aligned) {
                    console.log("aligned=", aligned);
                    this.$store.commit(this.namespace + '/setAligned', aligned);
                }
            },
            stockGraphType() {
                return this.$store.state[this.namespace].stockGraphType;
            },
            showTradingPeriod(){
                return this.$store.state[this.namespace].showTradingPeriod;
            }
        }
    };

</script>
