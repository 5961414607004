<template>
    <span>
        <b-button v-on:click="toggle" size="sm" style="font-weight: bold; border: 1px solid gray; ">
            {{label}}</b-button>
    </span>
</template>

<script>
    module.exports = {
        props: ['namespace'],
        methods: {
            toggle: function () {
                let show3D=this.$store.state[this.namespace].show3D;
                console.log("this.namespace=", this.namespace);
                console.log("show3D=", !show3D);
                this.$store.commit(this.namespace + '/setShow3D', !show3D);
            }
        },
        computed: {
            label() {
                return "3D chart";
            }
        }
    }
</script>
